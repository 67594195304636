<template>
    <div>
      <b-card border-variant="danger" v-if="errore_caricamento">
        <b-row class="match-height">
          <b-col
            md="8"
          >
            <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
            <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
          </b-col>
          <b-col 
            md="4"
            class="text-center"
          >
            <b-button
            variant="primary"
            :to="{ name: 'area-clienti-dashboard' }"
            >
                <feather-icon
                    icon="CornerUpLeftIcon"
                    size="16"
                    class="mr-1"
                />Home
            </b-button>
          </b-col>
        </b-row>
          
      </b-card>
          
      <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner  v-if="errore_caricamento == false"
            variant="primary"
            style="width: 3rem; height: 3rem;"
            label="loading"
        />
        <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
      </div>
  
      <b-card bg-variant="Default" v-if="Caricato">
        <b-row class="match-height">
          <b-col
            md="11"
            cols="10" 
            class="mb-2"
          >
            &nbsp;
          </b-col>
          <b-col
            md="1"
            cols="2"
            class="mb-2"
          >
            <b-button variant="outline-primary" class="px-0" @click.prevent="clickReset" block v-if="resetState != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
          </b-col>
          
        </b-row>
  
          <!-- table -->
          <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :rows="rows"
          :columns="columns"
          :isLoading=isLoading
          :search-options="{ 
              enabled: true,
              externalQuery: searchTerm }"
          :pagination-options="{
              enabled: true,
              perPage:pageLength
          }"
          >
          
          <template slot="loadingContent">
            <div class="text-center my-3">
                <b-spinner
                  variant="primary"
                  style="width: 3rem; height: 3rem;"
                  label="loading"
                />
                <div class="d-block pt-1 text-primary">Caricamento</div>
            </div>
          </template>
          
          <div slot="emptystate"> <div class="text-center py-4"> Nessun dato disponibile - (Messaggio personalizzabile) </div> </div>
  
          <template
              slot="table-row"
              slot-scope="props"
          >
  
              <!-- Column: license.price -->
              <span v-if="props.column.field === 'license.price'">
                {{ prezzoFormattato(props.formattedRow[props.column.field]) }}
              </span>
              
              <!-- Column: paid -->
              <span v-else-if="props.column.field === 'paid'">
                <feather-icon v-if="(props.formattedRow[props.column.field] === true)" icon="CheckIcon" class="text-primary" size="21" />
              </span>
              
              <!-- Column: disputa.id -->
              <span v-else-if="props.column.field === 'dispute.id'">
                <feather-icon v-if="(props.formattedRow[props.column.field])" icon="CheckIcon" class="text-primary" size="21" />
              </span>
              
              <!-- Column: refund -->
              <span v-else-if="props.column.field === 'refund'">
                <span v-if="(props.formattedRow[props.column.field])">{{ props.row.refund }}</span>
                <span v-else>
                  <span v-if="(props.row.paid === true)">rimborsabile</span>
                  <span v-else>---</span>
                </span>
              </span>
  
              <!-- Column: created_at -->
              <div v-else-if="props.column.field === 'created_at'">
                {{ formattazioneDataOra(props.row.created_at, 'it') }}
              </div>
  
              <!-- Column: updated_at -->
              <div v-else-if="props.column.field === 'updated_at'">
                {{ formattazioneDataOra(props.row.updated_at, 'it') }}
              </div>
  
              <!-- Column: azioni -->
              <span v-else-if="props.column.field === 'azioni'">
                <div class="text-nowrap">
                  <feather-icon
                    icon="ArrowRightIcon"
                    class="cursor-pointer text-primary"
                    size="21"
                    v-b-tooltip.hover.v-primary
                    title="Azioni"
                    :id="`tabellautenti-riga-${props.row.id}-sidebar-azioni`"
                    @click="sidebarAction(props.row.id, props.row.license, props.row.api_invoice, props.row.api_payment_intent, props.row.paid, props.row.refund, props.row.created_at, props.row.updated_at)"
                  />
                </div>
              </span>
  
              <!-- Column: standard -->
              <span v-else>
              {{ props.formattedRow[props.column.field] }}
              </span>
          </template>
  
          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >
              <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                  Visualizzazione da 1 a 
                  </span>
                  <b-form-select
                  v-model="pageLength"
                  :options="['10','25','50','100']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> di {{ props.total }} righe </span>
              </div>
              <div>
                  <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                  <template #prev-text>
                      <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                      />
                  </template>
                  <template #next-text>
                      <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                      />
                  </template>
                  </b-pagination>
              </div>
              </div>
          </template>
          </vue-good-table>
              
      </b-card>
  
      <b-sidebar
        ref="sidebar_azioni"
        id="sidebar-right"
        bg-variant="white"
        title="Riepilogo pagamento"
        right
        backdrop
        shadow
      >
        <div>
          <div class="py-0 px-2">
            
            <div class="divider">
                <div class="divider-text text-primary fw-bolder">DETTAGLI</div>
            </div>
            <b-row cols="1">
              <b-col class="riga_sidebar">
                <span class="font-weight-bold">Licenza:</span> {{ infoSideBar.license.ref_code }}
              </b-col>
              <b-col class="riga_sidebar">
                <span class="font-weight-bold">Tipo:</span> {{ infoSideBar.license.type }}
              </b-col>
              <b-col class="riga_sidebar">
                <span class="font-weight-bold">prezzo:</span> {{ infoSideBar.license.price }}
              </b-col>
            </b-row>
  
            <div class="divider">
              <div class="divider-text text-primary fw-bolder mt-0">Pagamento</div>
            </div>
            <b-row cols="1">
              <b-col class="riga_sidebar">
                <span class="font-weight-bold">Stato:</span>&nbsp;
                <strong v-if="infoSideBar.paid" class="text-primary">Pagato</strong>
                <strong v-else class="text-warning">Da pagare</strong>
              </b-col>
            </b-row>
  
            <div v-if="infoSideBar.refund">
              <div class="divider">
                <div class="divider-text text-primary fw-bolder mt-0">Riborsato</div>
              </div>
              <b-row cols="1">
                <b-col class="riga_sidebar">
                  <span class="font-weight-bold">Stato:</span> {{ infoSideBar.refund }}
                </b-col>
              </b-row>
            </div>
  
            <div class="divider">
              <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
            </div>
  
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary" block
              class="mb-1"
              @click="$router.push({ name: 'area-clienti-pagamenti-show', params: { id_riga: infoSideBar.id }})"
            >
              <feather-icon icon="EyeIcon" /> Visualizza dettagli
            </b-button>
          </div>
  
        </div>
      </b-sidebar>
    </div>
  </template>
  
  <script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    BCard, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, VBTooltip
  } from 'bootstrap-vue'
  import { VueGoodTable } from 'vue-good-table'
  import Ripple from 'vue-ripple-directive'
  import moment from 'moment'
  import { prezzoFormattato } from '@core/utils/funzioniGlobali' 
  
  export default {
    components: {
      BCardCode,
      VueGoodTable,
      BCard, 
      BCardText,
      BRow, 
      BCol, 
      BBadge,
      BPagination,
      BSpinner,
      BInputGroup,
      BFormGroup,
      BFormInput,
      BInputGroupPrepend,
      BInputGroupAppend,
      BDropdownDivider,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      BSidebar,
      BButton,
    },
    setup() {
        return {
            prezzoFormattato,
        }
    },
    directives: {
      'b-toggle': VBToggle,
      'b-tooltip': VBTooltip,
      Ripple,
    },
    data() {
      return {
        isLoading: true,
        emptystate: true, 
        pageLength: 10,
        dir: false,
        columns: [
          {
            label: 'Licenza',
            field: 'license.ref_code',
            width: '100px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Tipo',
            field: 'license.type',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Importo',
            field: 'license.price',
            width: '100px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Pagato',
            field: 'paid',
            width: '80px',
            tdClass: 'text-center',
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: 'Disputa',
            field: 'dispute.id',
            width: '80px',
            tdClass: 'text-center',
            html: true,
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: 'Rimborsato',
            field: 'refund',
            width: '110px',
            tdClass: 'text-center',
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: 'Creazione',
            field: 'created_at',
            width: '120px',
            filterOptions: {
              enabled: false,
            },
          },
          {
            label: 'Aggiornato',
            field: 'updated_at',
            width: '120px',
            filterOptions: {
              enabled: false,
            },
          },
  
          {
            label: '',
            field: 'azioni',
            width: '80px',
            sortable: false,
            tdClass: 'text-center',
          },
        ],
        rows: [],
        searchTerm: '',
        resetState: 'hidden',
        totalRecords: 0,
        serverParams: {
          columnFilters: {},
          sort: [
              {
              field: 'id',
              type: 'desc'
              }
          ],
          page: 1, 
          perPage: 10
        },
        startServerParams: {
          columnFilters: {},
          sort: [
              {
              field: 'id',
              type: 'desc'
              }
          ],
          page: 1, 
          perPage: 10
        },
        infoSideBar: {
          id: '',
          license: [ ],
          api_invoice: '',
          api_payment_intent: '',
          paid: '',
          refund: '',
          created_at: '',
          updated_at: '',
          titolo: '',
        },
        listSourceOption: [ ],
        listStatusOption: [ ],
        Caricato: false,
        errore_caricamento: false,
      }
    },
    computed: {
      statusVariant() {
        const statusColor = {
          /* eslint-disable key-spacing */
          2 : 'light-success',
          1 : 'light-danger',
          /* eslint-enable key-spacing */
        }
  
        return status => statusColor[status]
      },
    },
    mounted() {
      //in caso di resize del browser -> ricalcolo della larghezza della finestra
      window.onresize = () => {
        this.checkWindowsSizeAndResponsiveGoodTable();
      }
    },
    created() {
      this.checkWindowsSizeAndResponsiveGoodTable();
      
      //adesso faccio il caricamento dei dati
      this.loadItems();
  
      this.Caricato = true;
      this.errore_caricamento = false;
    },
    methods: {
      checkWindowsSizeAndResponsiveGoodTable(){
        if(window.innerWidth <1000){
          this.$set(this.columns[3], 'hidden', true)
          this.$set(this.columns[4], 'hidden', true)
          this.$set(this.columns[5], 'hidden', true)
        } else if(window.innerWidth <1200){
          this.$set(this.columns[3], 'hidden', false)
          this.$set(this.columns[4], 'hidden', false)
          this.$set(this.columns[5], 'hidden', true)
        } else if(window.innerWidth <1400){
          this.$set(this.columns[3], 'hidden', false)
          this.$set(this.columns[4], 'hidden', false)
          this.$set(this.columns[5], 'hidden', false)
        } else {
          //tutte le colonne visualizzate
          this.$set(this.columns[3], 'hidden', false)
          this.$set(this.columns[4], 'hidden', false)
          this.$set(this.columns[5], 'hidden', false)
        }
      },
      formattazioneDataOra(converted_at,lang) {
        if (converted_at) {
          return moment(String(converted_at)).locale(lang).format('DD/MM/YY - HH:mm')
        } else {
          return '---'
        }
      },
      clickReset(){
        console.log("clickReset");
  
        //forza reset filtro colonne
        this.$refs.table.reset();
  
        //forza reset parametri iniziali della tabella
        this.updateParams(this.startServerParams);
        this.loadItems();
  
        //reset campi ricerca
        this.campiRicerca.nome = '';
        this.campiRicerca.sezione = null;
        //disattiva bottone "reset"
        this.resetState = 'hidden';
      },
      advanceFilter(val) {
        //this.searchTerm = val
  
        this.updateParams(val);
        this.loadItems();
      },
      sidebarAction(id, license, api_invoice, api_payment_intent, paid, refund, created_at, updated_at) {
        console.log(refund);
  
        this.infoSideBar = { id: id,
          license: license,
          api_invoice: api_invoice,
          api_payment_intent: api_payment_intent,
          paid: paid,
          refund: refund,
          created_at: created_at,
          updated_at: updated_at, };
  
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
      },
      
      updateParams(newProps) {
        //console.log(newProps)

        //attiva bottone "reset" filtro o ricerca
        this.resetState = 'attiva';
  
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      
      onPageChange(params) {
        this.updateParams({page: params.currentPage});
        this.loadItems();
      },
  
      onPerPageChange(params) {
        this.updateParams({perPage: params.currentPerPage});
        this.loadItems();
      },
  
      onSortChange(params) {
        //console.log('cambio ordinamento')
        //console.log(params)
  
        let field_ricerca = '';
  
        //colonne con relazione
        if(params[0].field == 'source.text'){
          field_ricerca = 'source.name';
        } else if(params[0].field == 'aggregato_name_surname'){
          field_ricerca = 'user.surname';
        } else {
          field_ricerca = params[0].field;
        }
  
        this.updateParams({
          sort: [{
            type: params[0].type,
            field: field_ricerca,
          }],
        });
        this.loadItems();
      },
      
      onColumnFilter(params) {
        //console.log("AGGIORNAMENTO FILTRO COLONNA --------")
        this.updateParams(params);
        this.loadItems();
      },
  
      loadItems() {
        this.isLoading = true;
  
        if (typeof this.serverParams.columnFilters.aggregato_name_surname !== 'undefined'){
          var temporaneo_aggregato_name_surname = this.serverParams.columnFilters.aggregato_name_surname;
          delete(this.serverParams.columnFilters.aggregato_name_surname);
        }
  
        this.$http.get('api/customer/license-payment-logs/list', {
          params: this.serverParams 
          }).then(response => { 
              if(response.data.statusCode == 200){
                //ok
                this.totalRecords = response.data.reply.meta.total;
                this.rows = response.data.reply.data;
  
                //bind colonna "aggregato_name_surname" fix
                if (temporaneo_aggregato_name_surname !== 'undefined'){
                  this.serverParams.columnFilters.aggregato_name_surname = temporaneo_aggregato_name_surname;
                }
                
              } else if(response.data.statusCode == 422){
                //errori con testo di notifica ok dall'api
                this.$swal({
                  title: 'Errore',
                  text: 'codice errore: '+response.data.reply,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                  },
                  buttonsStyling: false,
                })
              } else {
                //tutto gli altri possibili errori
                this.$swal({
                  title: 'Errore Generico',
                  text: 'codice errore: '+response.data.statusCode,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                  },
                  buttonsStyling: false,
                })
              }
              
              //rimuovi il loading dopo il caricamento dei dati nella tabella
              this.isLoading = false;
          
          })
  
      },
    }
  }
  </script>
  
  <style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
  
  .riga_sidebar{
    padding-bottom: 5px;
  }
  </style>